import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, ListItemAvatar, Avatar, ListItemText, Badge, IconButton, CircularProgress } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import AlertStore from '../stores/AlertStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

const AlertsMenu = observer(() => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        AlertStore.startPolling();
    }, []);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getIcon = (severity) => {
        switch (severity) {
            case 'important':
                return <ErrorIcon />;
            case 'alert':
                return <WarningIcon />;
            case 'message':
                return <InfoIcon />;
            case 'reminder':
                return <MailIcon />;
            default:
                return <MailIcon />;
        }
    };

    const handleAlertClick = (id = '') => {
        navigate(`/alerts/${id}`);
    };

    const unreadAlerts = AlertStore.alerts.filter(alert => !alert.read);

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <Badge badgeContent={unreadAlerts.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onMouseLeave={handleMenuClose}
            >
                {AlertStore.isLoading ? (
                    <MenuItem>
                        <CircularProgress size={24} />
                    </MenuItem>
                ) : unreadAlerts.length === 0 ? (
                    <MenuItem onClick={() => handleAlertClick()}>
                        <ListItemText primary="No new notifications" />
                    </MenuItem>
                ) : (
                    unreadAlerts.map((notification, index) => (
                        <MenuItem key={index} onClick={() => handleAlertClick(notification._id)}>
                            <ListItemAvatar>
                                <Avatar>{getIcon(notification.severity)}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={notification.message} secondary={new Date(notification.createdAt).toLocaleString()} />
                        </MenuItem>
                    ))
                )}
            </Menu>
        </>
    );
});

export default AlertsMenu;
