import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { 
  Typography, Box, Snackbar, Alert, Tabs, Tab, useMediaQuery, useTheme, Card, CardContent 
} from '@mui/material';
import DeviceTable from '../components/DeviceTable';
import SummaryStatistics from '../components/SummaryStatistics';
import Map from '../components/Map';
import LogsComponent from '../components/LogsComponent';
import AnalyticsComponent from '../components/AnalyticsComponent';
import deviceStore from '../stores/DeviceStore';
import { useLocation } from 'react-router';

const Dashboard = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [selectedTab, setSelectedTab] = useState('inventory');
  const [isDeviceTableVisible, setIsDeviceTableVisible] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [stageId, setStageId] = useState(null);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }    

  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    deviceStore.loadDevices().catch((error) => {
      setSnackbarMessage(error.message || 'Failed to load units');
      setSeverity('error');
      setOpenSnackbar(true);
    });
  }, []);

  useEffect(() => {
    const tab = query.get('tab');
    const stageId = query.get('stageId');

    if (tab) {
      setSelectedTab(tab);
    }
    if (stageId) {
      setStageId(stageId);
    }
  }, [location.search]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleDeviceView = () => {
    setIsDeviceTableVisible(prev => !prev);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Dashboard Tabs" variant="scrollable" scrollButtons="auto">
        <Tab label="Inventory" value="inventory" />
        <Tab label="Map" value="map" />
        <Tab label="Logs" value="logs" />
        <Tab label="Analytics" value="analytics" />
      </Tabs>

      {selectedTab === 'inventory' && (
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <DeviceTable devices={deviceStore.devices}/>
          </CardContent>
        </Card>
      )}

      {selectedTab === 'map' && <Map />}
      {selectedTab === 'logs' && <LogsComponent />}
      {selectedTab === 'analytics' && <AnalyticsComponent stageId={stageId} />}

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default Dashboard;
