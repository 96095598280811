import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class DeviceStore {
  devices = [];
  deviceLogs = [];
  isLoading = false;
  error = null;
  selectedDevice = null;
  pagination = {};

  constructor() {
    makeAutoObservable(this);
  }

  async loadDevices() {
    this.setLoading(true);
    try {
      const data = await apiHandler.get('/devices');
      runInAction(() => {
        this.devices = data;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async loadDeviceById(deviceId) {
    this.setLoading(true);
    try {
      const device = await apiHandler.get(`/devices/${deviceId}`);
      runInAction(() => {
        this.selectedDevice = device;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);   
    }
  }

  async loadDeviceLogs({ deviceId, startDate, endDate, page = 1, limit = 10, sortBy = '', fields = '', search = '', searchField = 'name' }) {
    this.setLoading(true);
    try {
      let filterObj = {};
      if (startDate || endDate) {
        filterObj.timestamp = {};
        if (startDate) filterObj.timestamp.$gte = new Date(startDate).toISOString();
        if (endDate) filterObj.timestamp.$lte = new Date(endDate).toISOString();
      }
      if (deviceId) filterObj.device = deviceId;

      const filter = JSON.stringify(filterObj);
      const queryParams = new URLSearchParams({ page, limit, sortBy, filter, fields, search, searchField });

      const response = await apiHandler.get(`/devices/logs?${queryParams.toString()}`);
      runInAction(() => {
        this.deviceLogs = response.results;
        this.pagination = {
          hasMore: response.hasMore,
          totalCount: response.totalCount,
          currentPage: response.currentPage,
          pageSize: response.pageSize,
          totalPages: response.totalPages
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async updateDevice(deviceId, deviceData) {
    this.setLoading(true);
    try {
      const updatedDevice = await apiHandler.put(`/devices/${deviceId}`, deviceData);
      runInAction(() => {
        this.selectedDevice = updatedDevice;
        this.devices = this.devices.map(device => device.id === deviceId ? updatedDevice : device);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async createDevice(deviceData) {
    this.setLoading(true);
    try {
      const newDevice = await apiHandler.post('/devices', deviceData);
      runInAction(() => {
        this.devices.unshift(newDevice);
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  };

  async deleteDevice(deviceId) {
    this.setLoading(true);
    try {
      await apiHandler.delete(`/devices/${deviceId}`);
      runInAction(() => {
        this.devices = this.devices.filter(device => device.id !== deviceId);
        this.selectedDevice = null;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('DeviceStore Error:', this.error);
  }

  clearError() {
    this.error = null;
  }

  setSelectedDevice(device) {
    this.selectedDevice = device;
  }

  clearSelectedDevice() {
    this.selectedDevice = null;
  }
  
}


const deviceStore = new DeviceStore();
export default deviceStore;
