import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';
import { timer } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

class AuthStore {
    username = '';
    userId = null;
    email = '';
    password = '';
    token = null;
    isAuthenticated = false;
    userRoles = [];
    errorMessage = '';
    tokenDestructionTimer = null;
    isOTP = false;  // flag to indicate one-time password need
    needMFA = false;  // flag to indicate Multi-Factor Authentication requirement

    constructor() {
        makeAutoObservable(this);
        this.initializeAuthState();
    }

    async initializeAuthState() {
        const storedToken = window.localStorage.getItem('token');
        if (storedToken && this.isTokenValid(storedToken)) {
            this.setToken(storedToken);
            this.userId = window.localStorage.getItem('userId');
            this.scheduleTokenDestruction(storedToken);
        } else {
            this.logout();
        }
    }

    setUsername(username) {
        this.username = username;
    }

    setUserId(userId) {
        this.userId = userId;
        window.localStorage.setItem('userId', userId);
    }

    setEmail(email) {
        this.email = email;
    }

    setPassword(password) {
        this.password = password;
    }

    setErrorMessage(message) {
        this.errorMessage = message;
    }

    setIsAuthenticated(authStatus) {
        this.isAuthenticated = authStatus;
        if (!authStatus) {
            this.logout();
        }
    }

    setToken(tokenValue) {
        this.token = tokenValue;
        window.localStorage.setItem('token', tokenValue);
        if (tokenValue) {
            this.scheduleTokenDestruction(tokenValue);
        } else {
            this.clearTokenDestructionTimer();
        }
    }

    setUserRoles(roles) {
        this.userRoles = roles;
    }

    setIsOTP(isOTP) {
        this.isOTP = isOTP;
    }

    async login(username, password) {
        try {
            const response = await apiHandler.post('auth/signin', { username, password });
            if (response.needMFA) {
                this.userId = response.id;
                this.needMFA = true;
                this.isOTP = false;
                this.isAuthenticated = false;
            } else if (response.token) {
                this.completeLogin(response);
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            console.error('Error occurred while logging in:', error);
            this.setErrorMessage(error.message || 'Login failed');
            this.isAuthenticated = false;
            this.needMFA = false;
            throw error;
        }
    }

    completeLogin(data) {
        this.setToken(data.token);
        this.setUserId(data.id);
        this.setUserRoles(data.roles || []);
        this.setUsername(data.username);
        this.setEmail(data.email);
        this.setIsAuthenticated(true);
        this.isOTP = false;
        this.needMFA = false;
    }

    logout() {
        this.setUsername('');
        this.setPassword('');
        this.setEmail('');
        this.setToken(null);
        this.setUserId(null);
        this.userRoles = [];
        this.isAuthenticated = false;
        this.isOTP = false;
        this.needMFA = false;
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userId');
        this.clearTokenDestructionTimer();
    }

    destroyToken() {
        this.logout();
    }

    isTokenValid(token) {
        try {
            const decoded = jwtDecode(token);
            return decoded.exp > Date.now() / 1000;
        } catch (error) {
            return false;
        }
    }

    scheduleTokenDestruction(token) {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const delay = (decoded.exp - currentTime) * 1000;
        if (this.tokenDestructionTimer) {
            this.clearTokenDestructionTimer();
        }
        this.tokenDestructionTimer = timer(delay).subscribe(() => this.destroyToken());
    }

    clearTokenDestructionTimer() {
        if (this.tokenDestructionTimer) {
            this.tokenDestructionTimer.unsubscribe();
            this.tokenDestructionTimer = null;
        }
    }

    async sendMFAEmail() {
        try {
            await apiHandler.post('/auth/mfa/email', { userId: this.userId });
        } catch (error) {
            console.error('Error occurred while sending MFA email:', error);
            throw new Error('Failed to send MFA email');
        }
    }

    async sendSMSVerificationCode(phone, code) {
        try {
            await apiHandler.post('auth/mfa/sms', { phoneNumber: phone, code });
        } catch (error) {
            console.error('Error occurred while sending SMS verification code:', error);
            throw error;
        }
    }

    async sendEmailVerification(email) {
        try {
            await apiHandler.post('auth/send-verification-email', { email });
        } catch (error) {
            console.error('Error occurred while sending email verification:', error);
            throw error;
        }
    }

    async verifyEmail(token) {
        try {
            await apiHandler.post('auth/verify-email', { token });
        } catch (error) {
            console.error('Error occurred while verifying email:', error);
            throw error;
        }
    }

    async verifyMFA(code) {
        try {
        const response = await apiHandler.post('/auth/mfa/verify', { userId: this.userId, code });
        if (response.token && response.username && response.email) {
            this.completeLogin(response);
        }
        return response;  // Ensure this line is returning the server response.
        } catch (error) {
        console.error('Error occurred while verifying MFA:', error);
        throw new Error('MFA verification failed');
        }
    }
    
    }

const authStore = new AuthStore();
export default authStore;
