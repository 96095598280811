import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, Snackbar, Alert, Paper, Link } from '@mui/material';
import authStore from '../stores/AuthStore';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [countdown, setCountdown] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (countdown > 0 && openSnackbar) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      navigate('/login');
    }
    return () => clearTimeout(timer);
  }, [countdown, openSnackbar, navigate]);

  const handleSubmit = async () => {
  try {
    await authStore.forgotPassword(email);
    setSeverity('success');
    setSnackbarMessage('Password reset email sent. Redirecting in 15 seconds...');
    setOpenSnackbar(true);
    setCountdown(15);
  } catch (error) {
    setSeverity('error');
    setSnackbarMessage(error.message || 'Failed to send password reset email');
    setOpenSnackbar(true);
  }
};

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    clearTimeout();
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', my: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Forgot Password</Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" color="primary" onClick={handleSubmit}>Send Reset Email</Button>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <Link href="/login" color="inherit">Return to Login</Link>
          </Typography>
        </Box>
      </Box>
      <Snackbar open={openSnackbar} onClose={handleCloseSnackbar} autoHideDuration={15000}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage} {countdown > 0 && `(${countdown})`}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ForgotPasswordPage;
