import axios, { CancelToken } from 'axios';

class ApiHandler {
  lastApiHost = '';

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API || 'https://api.te3co.app/api',
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  getToken() {
    return window.localStorage.getItem('token');
  }

  setToken(token) {
    window.localStorage.setItem('token', token);
  }

  removeToken() {
    window.localStorage.removeItem('token');
  }

  async getHeaders() {
    const token = this.getToken();
    return {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    };
  }

  handleErrorCode(error) {
    if (error.response) {
      const status = error.response.status;
      const data = error.response.data;
      const message = data && data.message ? data.message : 'An unexpected error occurred.';
      console.error(`API Error (${status}): ${message}`, data);

      if (status === 401) {
        this.removeToken();
      }
    } else {
      console.error('API Request Error:', error.message);
    }
  }

  async request(method, endpoint, data, successMessage) {
    try {
      const headers = await this.getHeaders();
      const cancelToken = CancelToken.source();

      const response = await this.api.request({
        method,
        url: endpoint,
        data,
        headers,
        cancelToken: cancelToken.token,
      });

      this.updateLastApiHost(this.api.defaults.baseURL + endpoint);

      return response.data;
    } catch (error) {
      this.handleErrorCode(error);
      throw error;
    }
  }

  updateLastApiHost(url) {
    try {
      const urlObject = new URL(url);
      this.lastApiHost = urlObject.host;
    } catch (error) {
      console.error('Error parsing URL:', error);
    }
  }

  getLastApiHost() {
    return this.lastApiHost || "No API request made yet";
  }

  get(endpoint) {
    return this.request('get', endpoint);
  }

  post(endpoint, data, successMessage) {
    return this.request('post', endpoint, data, successMessage);
  }

  put(endpoint, data, successMessage) {
    return this.request('put', endpoint, data, successMessage);
  }

  delete(endpoint, data, successMessage) {
    return this.request('delete', endpoint, data, successMessage);
  }

  async pingDevice(deviceUrl) {
    try {
      const start = performance.now();
      await axios({
        method: 'GET',
        url: `http://${deviceUrl}`,
        timeout: 5000
      });
      const end = performance.now();
      return `Ping successful. Response time: ${end - start} ms`;
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        return "Ping timed out. Device might be offline.";
      } else {
        return "Ping failed. Device is likely offline.";
      }
    }
  }

  async getCurrentIP() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data.ip;
    } catch (error) {
      console.error('Error occurred while fetching current IP:', error);
      throw error;
    }
  }

  async getApiIP() {
    return Promise.resolve(this.getLastApiHost());
  }
}

const apiHandler = new ApiHandler();
export default apiHandler;
