import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom'; // Corrected import
import { List, ListItem, ListItemText, CircularProgress, Typography, Box, Paper } from '@mui/material';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import InfoIcon from '@mui/icons-material/Info'; // Example icon for detail
import deviceStore from '../stores/DeviceStore';

const DeviceList = observer(() => {
  const navigate = useNavigate(); // Replaced useHistory with useNavigate

  const handleListItemClick = (deviceId) => {
    navigate(`/unit/${deviceId}`);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Device List
      </Typography>
      {deviceStore.isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : deviceStore.devices.length > 0 ? (
        <List>
          {deviceStore.devices.map((device) => (
            <ListItem 
              button 
              key={device._id} 
              onClick={() => handleListItemClick(device._id)}
              divider
            >
              <ListItemText 
                primary={device.deviceName} 
                secondary={`Status: ${device.status} | Manufacturer: ${device.deviceManufacturer}`}
              />
              <InfoIcon color="action" />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
          <DeviceHubIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
          <Typography variant="subtitle1">No devices found</Typography>
          <Typography variant="body2" color="textSecondary">
            Add a new device or adjust your filters.
          </Typography>
        </Box>
      )}
    </>
  );
});

export default DeviceList;
