import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField, Button, CircularProgress, Snackbar, Alert, Typography, Box, Paper } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import AuthStore from '../stores/AuthStore';
import { useNavigate } from 'react-router-dom';
import authStore from '../stores/AuthStore';

const fadeInBlur = keyframes`
  0% {
    filter: blur(5px);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
`;

const StyledPaper = styled(Paper)({
  padding: "3rem",
  maxWidth: "500px",
  margin: "2rem auto",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
});

const MFA = observer(({ userEmail }) => {
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const handleSendCode = async () => {
    setIsLoading(true);
    try {
      await AuthStore.sendMFAEmail();
      setToastMessage('MFA code sent via Email successfully.');
      setShowToast(true);
    } catch (error) {
      setToastMessage(error.message || 'Failed to send MFA code via Email.');
      setShowToast(true);
    }
    setIsLoading(false);
  };

  const handleVerifyCode = async () => {
    if (!code.trim()) {
      setToastMessage('Please enter the MFA code.');
      setShowToast(true);
      return;
    }
    setIsLoading(true);
    try {
      const response = await AuthStore.verifyMFA(code);
      if (response) {
        setToastMessage('MFA code verified successfully.');
        const isItAdmin = authStore.userRoles.includes('ITADMIN');
        navigate(isItAdmin ? '/users' : '/');
      } else {
        throw new Error(response.message || 'MFA code verification failed.');
      }
      setShowToast(true);
    } catch (error) {
      setToastMessage(error.message || 'MFA code verification failed.');
      setShowToast(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleSendCode();
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background:
          'radial-gradient(circle, hsla(0, 0%, 2%, 1) 0%, hsla(260, 2%, 7%, 1) 64%, hsla(90, 30%, 10%, 1) 100%)',
      }}
    >
      <StyledPaper elevation={10}>
        <Typography variant="h5" component="h2" gutterBottom textAlign="center">
          Multi-Factor Authentication
        </Typography>
        <Typography variant="body1">
          As an extra layer of security, we sent a code to you.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1, mb: 3 }}>
          {userEmail}
        </Typography>
        <TextField
          label="Enter MFA Code"
          variant="outlined"
          fullWidth
          margin="normal"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          helperText="Please enter the code here."
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleVerifyCode}
          disabled={isLoading}
          sx={{ mt: 2 }}
        >
          Verify Code
        </Button>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={handleSendCode}
          disabled={isLoading}
          sx={{ mt: 1 }}
        >
          Resend Code
        </Button>
        {isLoading && <CircularProgress size={24} sx={{ mt: 2 }} />}
        <Snackbar
          open={showToast}
          autoHideDuration={6000}
          onClose={handleCloseToast}
        >
          <Alert onClose={handleCloseToast} severity="info" sx={{ width: '100%' }}>
            {toastMessage}
          </Alert>
        </Snackbar>
      </StyledPaper>
    </Box>
  );
});

export default MFA;
