import { Box, Grid, TextField, Typography } from "@mui/material";


const AddressComponent = ({ address, label, handleChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom>{label ? label : 'Address'}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Street"
            name="street"
            value={address?.street}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Apartment"
            name="line2"
            value={address?.line2}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={address?.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={address?.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Zip Code"
            name="postalCode"
            value={address?.postalCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="County"
            name="county"
            value={address?.county}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Box>
  )
};

export default AddressComponent;