import { makeAutoObservable, runInAction } from "mobx";
import apiHandler from '../handlers/ApiHandler';

class FlowStore {
  flowLogs = [];
  deviceId = null;
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadFlowLogs(deviceId) {
    this.setLoading(true);
    if (deviceId !== this.deviceId) this.flowLogs = [];
    this.deviceId = deviceId;
    try {
      const data = await apiHandler.get(`/flowLogs/device/${deviceId}`);
      runInAction(() => {
        this.flowLogs = data;
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    runInAction(() => {
      this.error = error.message || 'An unexpected error occurred';
    });
    console.error('FlowStore Error:', this.error);
  }
}

const flowStore = new FlowStore();
export default flowStore;
