import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

const CircleProgressWithGrade = ({ stage, pressure, isInStage }) => {
  const gradient = `conic-gradient(#6FC055 ${pressure}%, #ddd ${pressure}%)`;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={100} size={100} thickness={2} style={{ color: gradient }} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1" component="div" style={{ color: isInStage ? '#6FC055' : '#ddd' }}>
          {`${stage} stages`}
        </Typography>
        <Typography variant="subtitle2" component="div" style={{ color: isInStage ? '#6FC055' : '#ddd' }}>
          {isInStage ? 'In stage' : 'Not in stage'}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircleProgressWithGrade;