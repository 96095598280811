import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Typography, Paper, Button, TextField } from '@mui/material';
import authStore from '../stores/AuthStore';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [statusMessage, setStatusMessage] = useState('Verifying Email...');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const token = searchParams.get('token');

  useEffect(() => {
    if (authStore.isAuthenticated) {
      navigate('/');
      return;
    }

    if (token) {
      verifyEmail();
    } else {
      setError(true);
      setStatusMessage('Invalid or missing token. Unable to verify email.');
      setLoading(false);
    }
  }, [token, navigate]);

  const verifyEmail = async () => {
    try {
      await authStore.verifyEmail(token);
      setStatusMessage('Email verified. Redirecting to login page...');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError(true);
      setStatusMessage('Failed to verify email address.');
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    setLoading(true);
    try {
      await authStore.resendVerificationEmail(email);
      setStatusMessage('Verification email resent. Check your email and spam folder.');
      setError(false);
    } catch (error) {
      setStatusMessage('Failed to resend verification email. Please try again.');
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'radial-gradient(circle, hsla(0, 0%, 2%, 1) 0%, hsla(260, 2%, 7%, 1) 64%, hsla(90, 30%, 10%, 1) 100%)',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          maxWidth: '80%',
          width: 600,
          minHeight: 300,
          margin: 2,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h6">{statusMessage}</Typography>
        {error && (
          <>
            <TextField
              sx={{ mt: 2 }}
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <Button variant="outlined" sx={{ mt: 2 }} disabled={loading} onClick={resendVerificationEmail}>
              Resend Verification Email
            </Button>
          </>
        )}
        <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate('/login')}>
          Return to Login
        </Button>
      </Paper>
    </Box>
  );
};

export default VerifyEmail;
