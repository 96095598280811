import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Snackbar, Alert, Card, CardContent } from '@mui/material';
import AlertsTable from '../components/AlertsTable';
import alertStore from '../stores/AlertStore';

const AlertsPage = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  useEffect(() => {
    alertStore.loadAlerts().catch((error) => {
      setSnackbarMessage(error.message || 'Failed to load alerts');
      setSeverity('error');
      setOpenSnackbar(true);
    });
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 3 }}>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom sx={{m: 3}}>Alerts</Typography>
          <AlertsTable />
        </CardContent>
      </Card>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
});

export default AlertsPage;
