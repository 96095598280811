import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, CardActionArea, Typography, Box, Paper, Card, CardContent, CardActions, Button, CardMedia, IconButton, Snackbar, Alert } from '@mui/material';
import deviceStore from '../stores/DeviceStore';
import Rat from '../assets/rat.png';
import Bat from '../assets/bat.png';
import Default from '../assets/default.jpg';
import { useTheme } from '@mui/material';

const DeviceGrid = observer(() => {
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    deviceStore.loadDevices().catch((error) => {
      setSnackbar({ open: true, message: error.message || 'Failed to load devices', severity: 'error' });
    });
  }, []);

  const handleCardClick = (deviceId) => {
    navigate(`/unit/${deviceId}`);
  };

  const handleAddClick = () => {
    navigate('/edit-unit/new');
  }

  const getImageForDevice = (tags) => {
    if (!tags || tags.length === 0) return Default;
    if (tags.some(tag => tag.toLowerCase().includes('rat'))) return Rat;
    if (tags.some(tag => tag.toLowerCase().includes('bat'))) return Bat;
    return Default;
  };

  const theme = useTheme();

  return (
    <Box sx={{ p: 2, m: 2 }}>
      {deviceStore.isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : deviceStore.devices.length > 0 ? (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {deviceStore.devices.map((device) => (
            <Card key={device._id} sx={{ width: 300, transition: '0.3s', boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)', '&:hover': { boxShadow: `0 0 20px 5px ${theme.palette.secondary.main}` } }}>
              <CardActionArea onClick={() => handleCardClick(device._id)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={getImageForDevice(device?.deviceData?.tags)}
                  alt="Device Image"
                />
                <CardContent>
                  <Typography variant="h6">{device?.deviceData?.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Status: {device?.deviceData?.connection_status}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Alerts: {device?.deviceData?.alerts}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Group: {device?.deviceData?.group}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
          <Typography variant="subtitle1">No devices found</Typography>
          <Typography variant="body2" color="textSecondary">
            Add a new device or adjust your filters.
          </Typography>
        </Box>
      )}
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}} open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>{snackbar.message}</Alert>
      </Snackbar>
    </Box>
  );
});

export default DeviceGrid;
