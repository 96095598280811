import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import {
  Box, Card, CardContent, CircularProgress, Typography, FormControl, InputLabel, Select, MenuItem,
  OutlinedInput, TextField, Checkbox, ListItemText, Button, FormGroup, FormControlLabel,
  Switch, Slider
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Brush } from 'recharts';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import deviceStore from '../stores/DeviceStore';
import flowStore from '../stores/FlowStore';

const marks = [
  { value: 1, label: '1s' },
  { value: 5, label: '5s' },
  { value: 30, label: '30s' },
  { value: 60, label: '1m' },
  { value: 300, label: '5m' },
  { value: 900, label: '15m' },
  { value: 1800, label: '30m' },
];

const AnalyticsComponent = observer(({ stageId }) => {
  const [selectedDevice, setSelectedDevice] = useState('');
  const [selectedValves, setSelectedValves] = useState(['V1', 'V2']);
  const [chartData, setChartData] = useState({ V1: [], V2: [] });
  const [dateRange, setDateRange] = useState([dayjs().subtract(3, 'hours'), dayjs()]);
  const [interval, setInterval] = useState(60); // Default to 60 seconds
  const [isLoading, setIsLoading] = useState(false);
  const [showLinePressure, setShowLinePressure] = useState(true);
  const [showHydraulicPressure, setShowHydraulicPressure] = useState(true);
  const [showSystemVoltage, setShowSystemVoltage] = useState(true);

  const totalDurationSeconds = dateRange[1].diff(dateRange[0], 'seconds');

  useEffect(() => {

    async function fetchDevices() {
      try {
        await deviceStore.loadDevices();
      } catch (error) {
        console.error('Error loading units:', error);
      }
    }

    const stage = flowStore.flowLogs.find(stage => stage._id === stageId);

    if (stage) {
      setSelectedDevice(stage.device._id);
      const range = [dayjs(stage.flowStart), dayjs(stage.flowEnd)];
      setDateRange(range);
      loadLogs(range, stage.device._id);
    }

    fetchDevices();
  }, []);

  useEffect(() => {
    const newData = { V1: [], V2: [] };
    deviceStore.deviceLogs.forEach(log => {
      let details = typeof log.details === 'string' ? JSON.parse(log.details) : log.details;
      if (selectedValves.includes(details.ActiveValve)) {
        newData[details.ActiveValve].push({
          ...details,
          DateTime: new Date(log.timestamp).toISOString()
        });
      }
    });
    setChartData(newData);
  }, [deviceStore.deviceLogs, selectedValves]);

  useEffect(() => {

  }, [stageId])

  const loadLogs = useCallback(async (range, device) => {

    const searchRange = [range[0] || dateRange[0], range[1] || dateRange[1]]
    if (!device) device = selectedDevice;

    if (!device || !searchRange[0] || !searchRange[1]) return;
    setIsLoading(true);
    const startDate = searchRange[0].toISOString();
    const endDate = searchRange[1].toISOString();

    try {
      await deviceStore.loadDeviceLogs({
        deviceId: device,
        startDate,
        endDate,
        interval,
        desiredPoints: 500
      });
    } catch (error) {
      console.error('Failed to load unit logs:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDevice, dateRange, interval]);

  const shiftTimeWindow = (shift) => {
    setDateRange(oldDateRange => [
      oldDateRange[0].add(shift, 'hours'),
      oldDateRange[1].add(shift, 'hours')
    ]);
  };

  const dynamicMarks = useMemo(() => {
    const validMarks = marks.filter(mark => mark.value <= Math.min(totalDurationSeconds, 28800));
    return validMarks;
  }, [totalDurationSeconds]);

  const renderChart = (valveData, valveLabel) => (
    <Box sx={{ mb: 2, width: '100%', height: 300 }}>
      <ResponsiveContainer key={`chart-${valveLabel}`} width="100%" height="100%">
        <LineChart data={valveData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="DateTime" tickFormatter={(unixTime) => dayjs(unixTime).format('HH:mm:ss')} style={{ stroke: 'white', fill: 'white' }} />
          <YAxis style={{ stroke: 'white', fill: 'white' }} />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {showLinePressure && <Line type="monotone" dataKey="LinePressure" stroke="#8884d8" name="Line Pressure" dot={false} />}
          {showHydraulicPressure && <Line type="monotone" dataKey="HydraulicPressure" stroke="#82ca9d" name="Hydraulic Pressure" dot={false} />}
          {showSystemVoltage && <Line type="monotone" dataKey="SystemVoltage" stroke="#ffc658" name="System Voltage" dot={false} />}
          <Brush dataKey="DateTime" height={30} stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );

  return (
    <Box>
      <Card>
        <CardContent>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="device-select">Select Unit</InputLabel>
            <Select
              id="device-select"
              value={selectedDevice}
              onChange={(e) => {
                setSelectedDevice(e.target.value);
                deviceStore.setSelectedDevice(e.target.value);
              }}
              input={<OutlinedInput label="Select Unit" />}
            >
              {deviceStore.devices.map(device => (
                <MenuItem key={device._id} value={device._id}>{device.deviceName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel htmlFor="valve-select">Select Valves</InputLabel>
            <Select
              id="valve-select"
              multiple
              value={selectedValves}
              onChange={(e) => setSelectedValves(e.target.value)}
              input={<OutlinedInput label="Select Valves" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {['V1', 'V2'].map(valve => (
                <MenuItem key={valve} value={valve}>
                  <Checkbox checked={selectedValves.includes(valve)} />
                  <ListItemText primary={valve} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Slider
            value={interval}
            onChange={(event, newValue) => {
              if (marks.some(mark => mark.value === newValue)) {
                setInterval(newValue);
              }
            }}
            aria-labelledby="interval-slider"
            valueLabelDisplay="on"
            marks={marks.map(mark => ({ ...mark, value: Math.min(mark.value, totalDurationSeconds) }))}
            step={null}
            min={1}
            max={Math.min(totalDurationSeconds, 1800)}
            sx={{ mt: 6 }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', gap: 2, marginTop: 2 }}>
              <DateTimePicker
                label="Start Date"
                value={dateRange[0]}
                onChange={(newDate) => setDateRange([newDate, dateRange[1]])}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
              <DateTimePicker
                label="End Date"
                value={dateRange[1]}
                onChange={(newDate) => setDateRange([dateRange[0], newDate])}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
          </LocalizationProvider>
          <Box display="flex" justifyContent="space-between" my={2}>
            <Button onClick={() => shiftTimeWindow(-1)} startIcon={<ArrowBackIosIcon />}>Shift Left</Button>
            <Button onClick={() => shiftTimeWindow(1)} endIcon={<ArrowForwardIosIcon />}>Shift Right</Button>
          </Box>
          <FormGroup row>
            <FormControlLabel
              control={<Switch checked={showLinePressure} onChange={(e) => setShowLinePressure(e.target.checked)} />}
              label="Line Pressure"
            />
            <FormControlLabel
              control={<Switch checked={showHydraulicPressure} onChange={(e) => setShowHydraulicPressure(e.target.checked)} />}
              label="Hydraulic Pressure"
            />
            <FormControlLabel
              control={<Switch checked={showSystemVoltage} onChange={(e) => setShowSystemVoltage(e.target.checked)} />}
              label="System Voltage"
            />
          </FormGroup>
          <Button variant="contained" onClick={loadLogs} sx={{ mb: 2, mt: 2 }}>
            Load Data
          </Button>
          {isLoading ? <CircularProgress sx={{ ml: 2, mt: 2, mb: 1 }}/> : (
            <>
              {renderChart(chartData['V1'], 'Valve 1')}
              {renderChart(chartData['V2'], 'Valve 2')}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
});

export default AnalyticsComponent;
