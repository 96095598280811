import React from 'react';
import TextField from '@mui/material/TextField';

const StepPhoneNumber = ({ userData, setUserData }) => {
  return (
    <TextField
      label="Phone Number"
      type="phoneNumber"
      value={userData.phoneNumber}
      onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
      fullWidth
      variant="outlined"
      margin="normal"
    />
  );
};

export default StepPhoneNumber;