import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CircleProgressWithGrade from './CircleProgressWithGrade';
import { Button } from '@mui/material';

const calculateAverageStagesPerDay = (stages) => {
  if (!stages || stages.length === 0) return 0;
  const dateSet = new Set(stages.map(stage => new Date(stage.flowStart).setHours(0, 0, 0, 0)));
  const diffDays = dateSet.size;
  return stages.length / diffDays;
};

const StageIndicator = ({ stages = [] }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showDetail, setShowDetail] = useState(false);
  const [stagesToday, setStagesToday] = useState(0);
  const averageStagesPerDay = calculateAverageStagesPerDay(stages);

  useEffect(() => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    setStagesToday(stages.filter(stage => {
      const flowStartDate = new Date(stage.flowStart);
      return flowStartDate >= todayStart && flowStartDate <= todayEnd;
    }).length);
  }, [stages]);

  const handleStageClick = (deviceId) => {
    const queryParams = new URLSearchParams({ stageId: stages[0]._id });
    navigate(`/unit/${deviceId}?${queryParams.toString()}`);
  };

  const progressValue = averageStagesPerDay > 0 ? (stagesToday / averageStagesPerDay) * 100 : 0;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircleProgressWithGrade
          stage={stagesToday.toString()}
          pressure={progressValue}
          isInStage={stages.some(stage => stage.isFlowing)}
          size={100}
          thickness={5}
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.dark,
          }}
        />
        <Button
          variant="body1"
          sx={{ cursor: 'pointer', mt: 2 }}
          onClick={() => stages.length > 0 && handleStageClick(stages[0].device._id)}
          disabled={stages.length === 0}
        >
          {`${stages.length} stages`}
        </Button>
      </Box>
      {showDetail && stages.map((stage, index) => (
        <Box key={index} sx={{ mt: 1 }}>
          <Typography variant="body2">Stage ID: {stage._id}</Typography>
          <Typography variant="body2">Unit ID: {stage.device._id}</Typography>
          <Typography variant="body2">Is Flowing: {stage.isFlowing ? 'Yes' : 'No'}</Typography>
          <Typography variant="body2">Flow Start: {new Date(stage.flowStart).toLocaleString()}</Typography>
          <Typography variant="body2">Flow End: {new Date(stage.flowEnd).toLocaleString()}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default StageIndicator;
