import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, useMediaQuery } from '@mui/material';

import DeviceDetails from './pages/DeviceDetails';
import ForgotPassword from './pages/ForgotPassword';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import SettingsPage from './pages/Settings';
import SignUp from './pages/SignUp';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import DeviceList from './components/DeviceList';
import ProtectedRoute from './components/ProtectedRoute';
import DeviceGrid from './components/DeviceGrid';
import VerifyEmail from './pages/VerifyEmail';
import Help from './pages/Help';
import MFA from './pages/MFA';
import Dashboard from './pages/Dashboard';

import { LicenseInfo } from '@mui/x-data-grid-pro';
import UserForm from './pages/UserForm';
import AlertsPage from './pages/AlertsPage';
import DeviceForm from './components/DeviceForm';
import Onboarding from './pages/Onboarding';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_LICENSE);


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000000',
      paper: '#212121',
    },
    primary: {
      main: '#6FC055',
    },
    secondary: {
      main: '#BBBBBB',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#BBBBBB',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    // ... other typography variants
  },
});
const App = () => {
  const Layout = () => {
    const location = useLocation();
    const modalPages = ['/login', '/signup', '/forgot-password', '/reset-password', '/verify-email'];
    const isModal = modalPages.includes(location.pathname);
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column',
      }}>
        {!isModal && <Navbar/>}
        <Box sx={{ flexGrow: 1, marginLeft: isMobile ? 0 : isModal? 0 : '250px', transition: 'margin-left 0.3s ease', mt: isModal? 0 : 8}}>
          <Routes>
            <Route path="/" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><Dashboard /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><Dashboard /></ProtectedRoute>} />
            <Route path="/unit/:id" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><DeviceDetails /></ProtectedRoute>} />
            <Route path="/unit-status" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><DeviceGrid /></ProtectedRoute>} />
            <Route path="/edit-unit/:id" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><DeviceForm /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><SettingsPage /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/login/mfa" element={<MFA />} />
            <Route path="/onboarding" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><Onboarding /></ProtectedRoute>} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/help" element={<Help />} />
            <Route path="/alerts/:id" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><AlertsPage /></ProtectedRoute>} />
            <Route path="/alerts" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><AlertsPage /></ProtectedRoute>} />
            <Route path="*" element={<ProtectedRoute requiredRole={['USER', 'ADMIN']}><DeviceGrid /></ProtectedRoute>} />
          </Routes>
        </Box>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Layout />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
