import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class UserPreferencesStore {
  username = '';
  displayName = '';
  email = '';
  roles = [];
  fullName = '';
  address = {};
  phoneNumber = '';
  profileImage = '';
  isActive = true;
  MFA = false;
  mfaType = 'NONE';

  language = 'English';
  timeZone = 'UTC';
  dateFormat = 'MM/DD/YYYY';

  loading = false;

  constructor() {
    makeAutoObservable(this);
    this.loadUserDetails();
  }

  async loadUserDetails() {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.resetUserDetails();
      try {
        const response = await apiHandler.get(`users/${userId}`);
        const user = response;
        this.setUserDetails(user);
        return user;
      } catch (error) {
        console.error('Error loading user details:', error);
      }
    }
  }

  setUserDetails(user) {
    this.username = user.username;
    this.displayName = user.displayName;
    this.email = user.email;
    this.roles = user.roles;
    this.fullName = user.fullName;
    this.address = user.address;
    this.phoneNumber = user.phoneNumber;
    this.profileImage = user.profileImage;
    this.isActive = user.isActive;
    this.mfaType = user.mfaType || 'NONE';

    this.language = user.settings?.language || 'N/A';
    this.timeZone = user.settings?.timeZone || 'N/A';
    this.dateFormat = user.settings?.dateFormat || 'N/A';
    this.MFA = user.settings?.MFA || false;
  }

  resetUserDetails() {
    this.username = '';
    this.displayName = '';
    this.email = '';
    this.roles = [];
    this.fullName = '';
    this.address = {};
    this.phoneNumber = '';
    this.profileImage = '';
    this.isActive = true;
    this.mfaType = 'NONE'

    this.language = 'English (U.S.)';
    this.timeZone = 'UTC';
    this.dateFormat = 'MM/DD/YYYY';
    this.MFA = false;
  }

  async updateUserDetails(updatedDetails) {
    const userId = localStorage.getItem('userId');
    if (userId) {
      try {
        await apiHandler.put(`users/${userId}`, updatedDetails);
        this.setUserDetails(updatedDetails);
      } catch (error) {
        console.error('Error updating user details:', error);
        throw error;
      }
    }
  }

  async updatePreferences(language, timeZone, dateFormat, mfa) {
    const updatedUserSettings = {
      settings: {
        language,
        timeZone,
        dateFormat,
        MFA: mfa
      }
    };
    try {
      await this.updateUserDetails(updatedUserSettings);
    } catch (error) {
      console.error('Error updating user preferences:', error);
      throw error;
    }
  };

  userDetailsLoaded() {
    return this.username !== '' || this.loading;
  }
}

const userPreferencesStore = new UserPreferencesStore();

export default userPreferencesStore;
