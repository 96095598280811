import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class UsersStore {
  users = [];
  paginatedUsers = {
    hasMore: false,
    totalCount: 0,
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
    results: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  async fetchUsers() {
    try {
      const response = await apiHandler.get('/users');
      this.users = response;
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  async fetchPaginatedUsers(
    page = 1,
    pageSize = 10,
    sortBy = 'username',
    filter = {},
    search = '',
    searchField = 'username'
  ) {
    try {
      const { results, ...pagination } = await apiHandler.get(
        `/users?page=${page}&limit=${pageSize}&sortBy=${sortBy}&filter=${JSON.stringify(filter)}&search=${search}&searchField=${searchField}`
      );
      this.paginatedUsers = { ...pagination, results: results };
      console.log(this.paginatedUsers);
      return this.paginatedUsers;
    } catch (error) {
      console.error("Error fetching paginated users:", error);
      return;
    }
  };

  async fetchUserById(userId) {
    try {
      const response = await apiHandler.get(`/users/${userId}`);
      return response; // Assuming the response is the user object
    } catch (error) {
      console.error(`Error fetching user with ID ${userId}:`, error);
    }
  }

  async createUser(userData) {
    try {
      const response = await apiHandler.post('/users', userData);
      this.users.push(response.user); // Assuming the response is the new user object
      return;
    } catch (error) {
      console.error("Error creating user:", error);
    }
  }

  async updateUser(userId, updatedUserData) {
    try {
      const response = await apiHandler.put(`/users/${userId}`, updatedUserData);
      const updatedUser = response; // Assuming the response is the updated user object
      const index = this.users.findIndex(user => user._id === userId);
      if (index > -1) {
        this.users[index] = updatedUser;
      }
    } catch (error) {
      console.error(`Error updating user with ID ${userId}:`, error);
    }
  }

  async approveUser(userId, approval) {
    try {
      const response = await apiHandler.post(`/users/approve/${userId}`, { approved: approval });
      const updatedUser = response; // Assuming the response is the updated user object
      const index = this.users.findIndex(user => user._id === userId);
      if (index > -1) {
        this.users[index] = updatedUser;
      }
    } catch (error) {
      console.error(`Error approving user with ID ${userId}:`, error);
      throw error;
    }
  }

  async deleteUser(userId) {
    try {
      await apiHandler.delete(`/users/${userId}`);
      this.users = this.users.filter(user => user._id !== userId);
    } catch (error) {
      console.error(`Error deleting user with ID ${userId}:`, error);
    }
  }

  // Additional methods can be added as needed
  async sendOneTimePassword(userId, senderEmail) {
    try {
      await apiHandler.put(`/users/one-time-password/${userId}`, { email: senderEmail});
    } catch (error) {
      console.error(`Error sending OTP email to user with ID ${userId}:`, error);
    }
  }
}

export default new UsersStore();
