import React, { useState, useEffect } from "react"
import { Paper, Snackbar, Alert, Typography, TextField, Select, OutlinedInput, MenuItem, FormControl, InputLabel, Button, Box, FormControlLabel, Switch } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import deviceStore from "../stores/DeviceStore"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddressComponent from "./AddressComponent";
import mapboxUtils from "../utils/MapboxUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    }
  }
};

const DeviceForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [device, setDevice] = useState({
    deviceName: '',
    deviceManufacturer: '',
    manufacturerId: '',
    isActive: true,
    status: 'connected',
    notes: [],
    attachments: [],
    deviceData: {
      tags: [],
      name: '',
      connection_status: 'connected',
      alerts: 0,
      group: '',
      last_update: new Date().toISOString(),
      signal_strength: 0,
      health_status: 'normal'
    },
    currentLocation: {
      street: '',
      city: '',
      state: '',
      country: 'USA',
      postalCode: '',
      latLong: {
        lat: 0,
        long: 0
      }
    }
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id !== 'new') {
          deviceStore.loadDeviceById(id).then((device) => {
          })
        }
      } catch (error) {
        setSnackbar({ open: true, message: error.message, severity: 'error' })
      }
    }

    fetchData();
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false })
  };

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setDevice(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  };

  const handleDeviceDataChange = (event) => {
    const { name, value, checked, type } = event.target;
    setDevice(prev => ({
      ...prev,
      deviceData: {
        ...prev.deviceData,
        [name]: type === 'checkbox' ? checked : value
      }
    }))
  }

  const handleCurrentLocationChange = (event) => {
    const { name, value } = event.target;
    setDevice(prev => ({
      ...prev,
      currentLocation: {
        ...prev.currentLocation,
        [name]: value
      }
    }))
  }

  const handleMultiSelectChange = (name, value) => {
    setDevice(prev => ({
      ...prev,
      deviceData: {
        ...prev.deviceData,
        [name]: value
      }
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const deviceData = {
      ...device,
      deviceData: {
        ...device.deviceData,
        last_update: new Date().toISOString(),
        name: device.deviceName,
        connection_status: device.status,
      }
    }

    const addrString = `${device.currentLocation.street}, ${device.currentLocation.city}, ${device.currentLocation.state}, ${device.currentLocation.postalCode}`;

    try {
      const coordinates = await mapboxUtils.geocodeForward(addrString);
      deviceData.currentLocation.latLong = coordinates;
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' })
    }

    try {
      if (id === 'new') {
        deviceStore.createDevice(deviceData);
        setSnackbar({ open: true, message: 'Unit created', severity: 'success' })
        navigate('/unit-status');
      } else {
        deviceStore.updateDevice(id, deviceData);
        setSnackbar({ open: true, message: 'Unit updated', severity: 'success' })
        navigate('/unit-status');
      }
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' })
    }
  };

  const handleDeleteDevice = async () => {
    try {
      deviceStore.deleteDevice(id);
      navigate('/unit-status');
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' })
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ p: 3, margin: 6, flexGrow: 1 }}>
        <Typography variant="h6">{id === 'new' ? 'Add New Device' : 'Edit Device'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Device Name"
            name="deviceName"
            value={device.deviceName}
            onChange={handleChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Device Manufacturer</InputLabel>
            <Select
              name="deviceManufacturer"
              value={device.deviceManufacturer}
              onChange={handleChange}
              input={<OutlinedInput label="Device Manufacturer" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="Digi">Digi</MenuItem>
              <MenuItem value="PiMios">PiMios</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Manufacturer ID"
            name="manufacturerId"
            value={device.manufacturerId}
            onChange={handleChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={device.status}
              onChange={handleChange}
              input={<OutlinedInput label="Status" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="connected">Connected</MenuItem>
              <MenuItem value="disconnected">Disconnected</MenuItem>
              <MenuItem value="maintenance">Maintenance</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Tags</InputLabel>
            <Select
              name="tags"
              multiple
              value={device.deviceData.tags}
              onChange={(e) => handleMultiSelectChange('tags', e.target.value)}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              <MenuItem value="BAT">BAT</MenuItem>
              <MenuItem value="RAT">RAT</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Group"
            name="group"
            value={device.deviceData.group}
            onChange={handleDeviceDataChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Health Status</InputLabel>
            <Select
              name="health_status"
              value={device.deviceData.health_status}
              onChange={handleDeviceDataChange}
              input={<OutlinedInput label="Health Status" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="unknown">Unknown</MenuItem>
            </Select>
          </FormControl>
          <AddressComponent address={device.currentLocation} handleChange={handleCurrentLocationChange} label='Current Location'/>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 2 }}>
            <FormControlLabel
              control={<Switch checked={device.isActive} onChange={handleChange} name="isActive" />}
              label="Active"
            />
            {id !== 'new' && (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteDevice}
                sx={{ mt: 2 }}
              >
                Delete Device
              </Button>
            )}
            <Button variant="contained" type="submit" sx={{ mt: 2 }}>
              {id === 'new' ? 'Create Device' : 'Update Device'}
            </Button>
            <Button variant="outlined" onClick={() => navigate('/unit-status')} sx={{ mt: 2 }}>
              Cancel
            </Button>
          </Box>
        </form>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DeviceForm;