import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, TextField, Typography, Snackbar, Alert, Paper } from '@mui/material';
import authStore from '../stores/AuthStore';
import PasswordStrengthChecker from '../components/PasswordStrengthChecker'; // Import the component

const SignupPage = observer(() => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleSignup = async () => {
    try {
      await authStore.signUp();
      setSeverity('success');
      setSnackbarMessage('Signup successful');
    } catch (error) {
      setSeverity('error');
      setSnackbarMessage(error.message || 'Signup failed');
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handlePasswordChange = (passwordValue) => {
      authStore.setPassword(passwordValue);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', my: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Signup</Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField label="Username" variant="outlined" value={authStore.username} onChange={(e) => authStore.setUsername(e.target.value)} />
        <TextField label="Email" variant="outlined" value={authStore.email} onChange={(e) => authStore.setEmail(e.target.value)} />
        <PasswordStrengthChecker value={authStore.password} onChange={handlePasswordChange} />
        <Button variant="contained" color="primary" onClick={handleSignup}>Signup</Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
});

export default SignupPage;
