import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Typography, Snackbar, Alert, Paper } from '@mui/material';
import authStore from '../stores/AuthStore';
import PasswordStrengthChecker from '../components/PasswordStrengthChecker';

const ResetPasswordPage = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('info');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = async () => {
    try {
      await authStore.resetPassword(token, newPassword);
      setSeverity('success');
      setSnackbarMessage('Password successfully reset');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setSeverity('error');
      setSnackbarMessage(error.message || 'Failed to reset password');
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 400, mx: 'auto', my: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Reset Password</Typography>
      <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <PasswordStrengthChecker value={newPassword} onChange={(newValue) => setNewPassword(newValue)} />
        <Button variant="contained" color="primary" onClick={handleSubmit}>Reset Password</Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ResetPasswordPage;
