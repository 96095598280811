import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Paper, Divider, TextField, FormControlLabel, Switch, Grid, Select, MenuItem, Snackbar, Alert, Button, Slider } from '@mui/material';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import PeopleIcon from '@mui/icons-material/People';
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhonelinkLockIcon from '@mui/icons-material/PhonelinkLock';
import authStore from '../stores/AuthStore';
import apiHandler from '../handlers/ApiHandler';
import userPreferencesStore from '../stores/UserPreferencesStore';

const SettingsPage = observer(() => {
  // API Settings
  const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_API || '');
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(authStore.userId);
  const [token, setToken] = useState('');
  const [debugMode, setDebugMode] = useState(false);
  const [connectionSpeed, setConnectionSpeed] = useState('Not Available');
  const [currentIP, setCurrentIP] = useState('Not Available');
  const [apiIP, setApiIP] = useState('Not Available');

  // User Details
  const [username, setUsername] = useState(userPreferencesStore.username);
  const [displayName, setDisplayName] = useState(userPreferencesStore.displayName);
  const [email, setEmail] = useState(userPreferencesStore.email);
  const [mfaType, setMfaType] = useState(userPreferencesStore.mfaType);
  
  // Locale Preferences
  const [langauge, setLanguage] = useState(userPreferencesStore.language);
  const [timeZone, setTimeZone] = useState(userPreferencesStore.timeZone);
  const [dateFormat, setDateFormat] = useState(userPreferencesStore.dateFormat);
  const [MFA, setMfa] = useState(userPreferencesStore.MFA);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const handleLanguageChange = (event) => {
    const language = event.target.value || '';
    setLanguage(language);
    try {
      userPreferencesStore.updatePreferences(language, timeZone, dateFormat, MFA);
      setSnackbar({ open: true, message: 'Language updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user preferences:', error);
      setSnackbar({ open: true, message: 'Failed to update language', severity: 'error' });
    }
  };

  const handleTimeZoneChange = (event) => {
    const timeZone = event.target.value || '';
    setTimeZone(timeZone);
    try {
      userPreferencesStore.updatePreferences(langauge, timeZone, dateFormat, MFA);
      setSnackbar({ open: true, message: 'Time Zone updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user preferences:', error);
      setSnackbar({ open: true, message: 'Failed to update time zone', severity: 'error' });
    }
  };

  const handleDateFormatChange = (event) => {
    const dateFormat = event.target.value || '';
    setDateFormat(dateFormat);
    try {
      userPreferencesStore.updatePreferences(langauge, timeZone, dateFormat, MFA);
      setSnackbar({ open: true, message: 'Date Format updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user preferences:', error);
      setSnackbar({ open: true, message: 'Failed to update date format', severity: 'error' });
    }
  };

  const handleMfaChange = (event) => {
    const mfa = event.target.checked;
    setMfa(mfa);
    try {
      userPreferencesStore.updatePreferences(langauge, timeZone, dateFormat, mfa);
      setSnackbar({ open: true, message: 'MFA updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user preferences:', error);
      setSnackbar({ open: true, message: 'Failed to update MFA', severity: 'error' });
    }
  }

  const handleMfaTypeChange = (event) => {
    const mfaType = event.target.value || '';
    setMfaType(mfaType);
    const updatedDetails = {
      mfaType
    };
    try {
      userPreferencesStore.updateUserDetails(updatedDetails);
      setSnackbar({ open: true, message: 'MFA type updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user details:', error);
      setSnackbar({ open: true, message: 'Failed to update MFA type', severity: 'error' });
    }
  }

  const handleSaveUser = async () => {
    const updatedDetails = {
      username,
      displayName,
      email,
      mfaType
    };
    try {
      await userPreferencesStore.updateUserDetails(updatedDetails);
      setSnackbar({ open: true, message: 'User details updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Failed to update user details:', error);
      setSnackbar({ open: true, message: 'Failed to update user details', severity: 'error' });
    }
  };

  useEffect(() => {
    setUserId(authStore.userId);
  }, [authStore.userId]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await userPreferencesStore.loadUserDetails(userId);
        setUsername(userData.username);
        setDisplayName(userData.displayName);
        setEmail(userData.email);
        setLanguage(userData.settings?.language || "English (U.S.)");
        setTimeZone(userData.settings?.timeZone || "UTC");
        setDateFormat(userData.settings?.dateFormat || "YYYY-MM-DD");
        setMfa(userData.settings?.MFA || false);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    setApiUrl(authStore.apiUrl); // Replace with actual logic to get API URL from authStore
    setToken(authStore.token); // Replace with actual logic to get token from authStore
    setDebugMode(authStore.debugMode); // Replace with actual logic to get debug mode from authStore

    const fetchData = async () => {
      try {
        const pingResponse = await apiHandler.ping(authStore.apiUrl);
        const currentTime = new Date().getTime();
        setConnectionSpeed(`${currentTime - pingResponse.startTime} ms`);
      } catch (error) {
        setConnectionSpeed('Unavailable');
      }

      try {
        setCurrentIP(await apiHandler.getCurrentIP());
        setApiIP(await apiHandler.getApiIP());
      } catch (error) {
        setCurrentIP('Unavailable');
        setApiIP('Unavailable');
      }
    };

    fetchData();
  }, [authStore.apiUrl, authStore.token, authStore.debugMode, userPreferencesStore.language, userPreferencesStore.timeZone, userPreferencesStore.dateFormat, userPreferencesStore.username, userPreferencesStore.displayName, userPreferencesStore.email]);

  return (

    loading ? (
      <div>Loading...</div>
    ) : (
      <>
        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
          <Typography variant="h5" gutterBottom color="primary">
            Account
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <AccountCircleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Username
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PeopleIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Display Name
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <KeyIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Email
            </Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1 }}>
            <Button variant='contained' onClick={handleSaveUser}>Save</Button>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
          <Typography variant="h5" gutterBottom color="primary">
            Locale
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <LanguageIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Language
            </Typography>
            <Select
              value={langauge}
              onChange={handleLanguageChange}
              sx={{ marginLeft: 'auto', minWidth: 120 }}
            >
              <MenuItem value="English (U.S.)">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              <MenuItem value="French">French</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PublicIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Time Zone
            </Typography>
            <Select
              value={timeZone}
              onChange={handleTimeZoneChange}
              sx={{ marginLeft: 'auto', minWidth: 120 }}
            >
              <MenuItem value="UTC">UTC</MenuItem>
              <MenuItem value="GMT">GMT</MenuItem>
              <MenuItem value="New York (EST; UTC -05:00)">EST</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Date Format
            </Typography>
            <Select
              value={dateFormat}
              onChange={handleDateFormatChange}
              sx={{ marginLeft: 'auto', minWidth: 120 }}
            >
              <MenuItem value="MM/DD/YYYY">MM/DD/YYYY</MenuItem>
              <MenuItem value="DD/MM/YYYY">DD/MM/YYYY</MenuItem>
              <MenuItem value="YYYY-MM-DD">YYYY-MM-DD</MenuItem>
            </Select>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <SecurityIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              MFA
            </Typography>
            <Switch sx={{ marginLeft: 'auto' }} value={MFA} onChange={handleMfaChange} defaultChecked={MFA} />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PhonelinkLockIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              MFA type
            </Typography>
            <Select
              value={mfaType}
              onChange={handleMfaTypeChange}
              sx={{ marginLeft: 'auto', minWidth: 120 }}
            >
              <MenuItem value="NONE">None</MenuItem>
              <MenuItem value="SMS">SMS</MenuItem>
              <MenuItem value="EMAIL">Email</MenuItem>
            </Select>
          </Box>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
          <Typography variant="h5" gutterBottom color="primary">
            API
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="API URL"
                variant="outlined"
                fullWidth
                value={apiUrl}
                onChange={(e) => setApiUrl(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label="Token"
                variant="outlined"
                fullWidth
                value={token}
                onChange={(e) => setToken(e.target.value)}
                sx={{ mb: 2 }}
              />
              <FormControlLabel
                control={<Switch checked={debugMode} onChange={(e) => setDebugMode(e.target.checked)} />}
                label="Debug Mode"
                labelPlacement="start"
                sx={{ mb: 2, marginLeft: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SettingsEthernetIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="body1">Connection Speed: {connectionSpeed}</Typography>
              </Box>
              <Typography variant="body1" sx={{ mb: 2 }}>Current IP: {currentIP}</Typography>
              <Typography variant="body1">API: {apiIP}</Typography>
            </Grid>
          </Grid>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>{snackbar.message}</Alert>
          </Snackbar>
        </Paper>
      </>
    )
  );
});

export default SettingsPage;
