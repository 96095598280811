import React, { useState } from 'react';
import {
  Typography, Box, List, ListItem, ListItemText,
  IconButton, CircularProgress, Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import useMediaQuery from '@mui/material/useMediaQuery';

const DeviceStages = ({ flowLogs, isLoading, handleStageClick }) => {
  const [expanded, setExpanded] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const columns = [
    { field: 'stage', headerName: 'Stage', width: 150 },
    { field: 'flowStart', headerName: 'Flow Start', width: 200 },
    { field: 'flowEnd', headerName: 'Flow End', width: 200 },
    {
      field: 'status', headerName: 'Status', width: 150,
      renderCell: (params) =>
        params.value ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />
    },
    {
      field: 'info', headerName: 'Info', width: 100,
      renderCell: (params) => (
        <IconButton edge="end" aria-label="info" onClick={() => handleStageClick(params.row)}>
          <InfoIcon />
        </IconButton>
      ),
    },
  ];

  const rows = flowLogs.map((log, index) => ({
    id: log._id,
    stage: `Stage ${index + 1}`,
    flowStart: dayjs(log.flowStart).format('YYYY-MM-DD HH:mm:ss'),
    flowEnd: dayjs(log.flowEnd).format('YYYY-MM-DD HH:mm:ss'),
    status: log.isFlowing,
  }));

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2 }}>
        <Typography variant="h6">Stages</Typography>
      </Box>
        <>
          <Divider sx={{ mb: 1 }} />
          {isLoading ? (
            <CircularProgress size={24} sx={{ display: 'block', mx: 'auto', my: 2 }} />
          ) : flowLogs.length > 0 ? (
            isMobile ? (
              <List sx={{ maxHeight: 400, overflow: 'auto' }}>
                {flowLogs.map((log, index) => (
                  <ListItem key={log._id} divider>
                    <ListItemText
                      primary={`Stage ${index + 1}`}
                      secondary={`${dayjs(log.flowStart).format('YYYY-MM-DD HH:mm:ss')} to ${dayjs(log.flowEnd).format('YYYY-MM-DD HH:mm:ss')}`}
                    />
                    <IconButton edge="end" aria-label="info">
                      <InfoIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </Box>
            )
          ) : (
            <Typography variant="body1" sx={{ my: 2 }}>No stages available.</Typography>
          )}
        </>
    </Box>
  );
};

export default DeviceStages;
