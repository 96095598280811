import { makeAutoObservable } from "mobx";
import { interval, Subscription } from "rxjs";
import apiHandler from '../handlers/ApiHandler';

class AlertStore {
  alerts = [];
  isLoading = false;
  error = null;
  pagination = {};
  pollingSubscription = new Subscription();

  constructor() {
    makeAutoObservable(this);
    this.initPolling();
  }

  initPolling = () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.startPolling(userId);
    }
  };

  startPolling = (userId) => {
    this.pollingSubscription.unsubscribe();
    this.pollingSubscription = interval(60000)
      .subscribe(() => {
        this.loadAlerts(userId);
      });
  };

  loadAlerts = async (userId) => {
    this.setLoading(true);
    try {
      const filters = { user: userId };
      const filter = JSON.stringify(filters);
      const queryParams = new URLSearchParams({ page: 1, limit: 10, sortBy: '', filter, fields: '', search: '', searchField: 'message' });

      const response = await apiHandler.get(`/alerts?${queryParams.toString()}`);
      this.alerts = response.results;
      this.pagination = response.pagination;
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  };

  async markAlertAsRead(alertId) {
    this.setLoading(true);
    try {
      const updatedAlert = await apiHandler.put(`/alerts/mark-read/${alertId}`);
      this.alerts = this.alerts.map(alert => alert.id === alertId ? updatedAlert : alert);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async markAlertAsUnread(alertId) {
    this.setLoading(true);
    try {
      const updatedAlert = await apiHandler.put(`/alerts/mark-unread/${alertId}`);
      this.alerts = this.alerts.map(alert => alert.id === alertId ? updatedAlert : alert);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setLoading(false);
    }
  }

  setLoading(loading) {
    this.isLoading = loading;
  }

  handleError(error) {
    this.error = error.message || 'An unexpected error occurred';
  }

  clearError() {
    this.error = null;
  }
}

export default new AlertStore();
