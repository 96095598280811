import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Typography, CircularProgress, Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import deviceDetailStore from '../stores/DeviceDetailStore';
import dayjs from 'dayjs';

const DeviceLogs = observer(() => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const loadLogs = async () => {
      const deviceId = deviceDetailStore.deviceInfo?._id;
      if (deviceId) {
        await deviceDetailStore.loadRealTimeData(deviceId);
        const data = deviceDetailStore.realTimeData;
        if (data && data.length > 0) {
          const transformedLogs = data.map((log, index) => ({
            id: index,
            ...log,
            formattedDateTime: log.DateTime ? dayjs(log.DateTime).format('YYYY-MM-DD HH:mm:ss') : '',
          }));
          setLogs(transformedLogs);
        }
      }
    };

    loadLogs();

    const intervalId = setInterval(() => {
      loadLogs();
    }, 300000); // Refetch data every 5 minutes

    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    { field: 'formattedDateTime', headerName: 'Date & Time', width: 200 },
    { field: 'ActiveValve', headerName: 'Valve', width: 100 },
    { field: 'LinePressure', headerName: 'Pressure', width: 130 },
    { field: 'SystemVoltage', headerName: 'Voltage', width: 130 },
    { field: 'SetPressure', headerName: 'Set Pressure', width: 130 },
    { field: 'ResetPressure', headerName: 'Reset Pressure', width: 130 },
    { field: 'IndicationPressure', headerName: 'Indication Pressure', width: 150 },
    { field: 'HydraulicPressure', headerName: 'Hydraulic Pressure', width: 150 },
    { field: 'ValveStateSetting', headerName: 'Valve State Setting', width: 150 },
    { field: 'ValveClosedState', headerName: 'Valve Closed State', width: 150 },
    { field: 'ValveOpenedState', headerName: 'Valve Opened State', width: 150 },
    { field: 'AlarmFaultCode1', headerName: 'Alarm Fault Code 1', width: 150 },
    { field: 'AlarmFaultCode2', headerName: 'Alarm Fault Code 2', width: 150 },
  ];

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Unit Logs</Typography>
      {deviceDetailStore.isLoading ? (
        <CircularProgress />
      ) : (
        <DataGrid
          rows={logs}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      )}
    </Box>
  );
});

export default DeviceLogs;
