import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Avatar,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
  CssBaseline,
  Menu,
  MenuItem,
  Badge,
  Checkbox,
  ListItemAvatar,
  Select,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PublicIcon from '@mui/icons-material/Public';
import ArticleIcon from '@mui/icons-material/Article';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import authStore from '../stores/AuthStore';
import UsersStore from '../stores/UsersStore';
import logo from '../assets/e3logo.png';
import AlertsMenu from './AlertsMenu';
import { toJS } from 'mobx';


const getInitials = (name) => {
  return name.split(' ').map(n => n[0]).join('').toUpperCase();
}

const getRandomColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = Math.floor(Math.random() * 100);
  const lightness = Math.floor(Math.random() * (75 - 50) + 50);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

const fetchUserData = () => {
  if (authStore.isAuthenticated) {
    return {
      username: authStore.username,
      avatarColor: getRandomColor()
    };
  } else {
    return {
      username: 'Unknown User',
      avatarColor: getRandomColor()
    };
  }
}

const Navbar = ({ title }) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [locations, setLocations] = useState([]);
  const isSmallerScreen = useMediaQuery('(max-width:600px)');
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState(null);
  const handleSettingsMenuOpen = (event) => setSettingsMenuAnchor(event.currentTarget);
  const handleCloseSettingsMenu = () => setSettingsMenuAnchor(null);
  const userRoles = toJS(authStore.userRoles);

  const isSuperAdmin = userRoles.includes('SUPERADMIN');
  const isAdmin = userRoles.includes('ADMIN');
  const isItAdmin = userRoles.includes('ITADMIN');
  const isUser = userRoles.includes('USER');

  const links = [
    isUser || isSuperAdmin || isAdmin ? 'Dashboard' : null,
    isUser || isSuperAdmin || isAdmin ? 'Unit Status' : null,
    isUser || isSuperAdmin || isAdmin ? 'Settings' : null,
  ].filter(link => link !== null);

  const iconMapping = {
    'Dashboard': <DashboardIcon />,
    'Unit Status': <DeviceHubIcon />,
    'Settings': <SettingsIcon />,
    'Users': <PeopleIcon />,
    'Legal': <ArticleIcon />,
    'Privacy Policy': <ArticleIcon />,
    'Support': <HelpIcon />,
    'Map': <PublicIcon />,
  };

  useEffect(() => {
    console.log(title, params[0]);
  }, [title, params[0]]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    authStore.logout();
    navigate('/login');
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.includes('All Locations')) {
      setLocations(['All Locations']);
    } else if (locations.includes('All Locations')) {
      setLocations(value.filter((v) => v !== 'All Locations'));
    } else {
      setLocations(value);
    }
  };

  const pageTitle = title || params[0];

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const userData = fetchUserData();
    setUserData(userData);
  }, [authStore.username, authStore.isAuthenticated]);

  const drawerContent = (
    <Box
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
      sx={{ width: 250, height: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
    >
      <Box sx={{ p: 1, pl: 2.5, display: 'flex', flexDirection: 'column', alignItems: 'left', backgroundColor: '#6FC055' }}>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>Customer Portal</Typography>
        <Typography variant="subtitle2">Te3co</Typography>
      </Box>
      <Divider />
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <List>
          {links.map((text) => (
            <ListItem button key={text} component={Link} to={text === 'Dashboard' ? '/' : `/${text.toLowerCase().replace(/ /g, '-')}`}>
              <ListItemIcon>
                {iconMapping[text]}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <List>
            <Typography variant='body1' fontWeight="light" style={{ color: 'grey', letterSpacing: '2px', paddingLeft: '10px' }}> ADMINISTRATION </Typography>
            {['Legal', 'Support'].map((text) => (
              <ListItem button key={text} component={Link} to={text === 'Settings' ? '/' : `/${text.toLowerCase().replace(/ /g, '-')}`}>
                <ListItemIcon>
                  {iconMapping[text]}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <img src={logo} alt="Logo" style={{ width: '100px', marginBottom: '10px' }} />
            <Typography variant="body2">Version: 1.0.0</Typography>
            <Typography variant="body2">Build Date/Time: {new Date().toLocaleString()}</Typography>
            <Typography variant="body2" style={{ marginTop: '10px' }}>&copy; {new Date().getFullYear()} Te3co. All rights reserved.</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: '#212121', backgroundImage: 'none', marginLeft: isSmallerScreen ? 0 : '250px' }}>
        <Toolbar sx={{ height: '64px' }}>
          {isSmallerScreen && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: isSmallerScreen ? '0' : '250px' }}>

            <Typography variant="h4" fontWeight='bold' textTransform='capitalize' noWrap>
              {/* {pageTitle || 'Dashboard'} */}
            </Typography>

            <Box sx={{ width: '200px', display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-evenly' }}>
              <Link to="/help" style={{ color: 'inherit' }}>
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </Link>

              <AlertsMenu />

              <IconButton onClick={handleSettingsMenuOpen}>
                {userData && (
                  <Avatar sx={{ bgcolor: userData.avatarColor, color: 'white' }}>
                    {getInitials(userData.username)}
                  </Avatar>
                )}
              </IconButton>
              <Menu
                anchorEl={settingsMenuAnchor}
                open={Boolean(settingsMenuAnchor)}
                onClose={handleCloseSettingsMenu}
              >
                <MenuItem onClick={() => { }}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Profile" />
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        variant={isSmallerScreen ? "temporary" : "permanent"}
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
            boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}


export default Navbar;