import { makeAutoObservable } from 'mobx';
import apiHandler from '../handlers/ApiHandler';

class RolesStore {
  roles = [];
  isLoading = false;
  error = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchRoles() {
    this.isLoading = true;
    this.error = null;
    try {
      const response = await apiHandler.get('/roles');
      this.roles = response;
    } catch (error) {
      this.error = error.toString();
      console.error("Error fetching roles:", error);
    } finally {
      this.isLoading = false;
    }
  }

  async createRole(newRole) {
    try {
      const response = await apiHandler.post('/roles', newRole);
      this.roles.push(response);
    } catch (error) {
      this.error = error.toString();
      console.error("Error creating role:", error);
    }
  }

  async updateRole(roleId, roleData) {
    try {
      const response = await apiHandler.put(`/roles/${roleId}`, roleData);
      const index = this.roles.findIndex((role) => role._id === roleId);
      if (index !== -1) {
        this.roles[index] = response;
      }
    } catch (error) {
      this.error = error.toString();
      console.error(`Error updating role with ID ${roleId}:`, error);
    }
  }

  async deleteRole(roleId) {
    try {
      await apiHandler.delete(`/roles/${roleId}`);
      this.roles = this.roles.filter((role) => role._id !== roleId);
    } catch (error) {
      this.error = error.toString();
      console.error(`Error deleting role with ID ${roleId}:`, error);
    }
  }

  getRoleById(roleId) {
    return this.roles.find((role) => role._id === roleId);
  }

  clearError() {
    this.error = null;
  }
}

const rolesStore = new RolesStore();
export default rolesStore;
