import axios from 'axios';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const token = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

class MapboxUtils {

  async geocodeForward(address) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${mapboxgl.accessToken}`;

    try {
      const response = await axios.get(url);

      const coordinates = response.data.features[0]?.center;
      if (!coordinates) {
        throw new Error('Coordinates not found');
      }

      return { lat: coordinates[1], long: coordinates[0] };
    } catch (error) {
      throw error;
    }
  }

};  

const mapboxUtils = new MapboxUtils();
export default mapboxUtils;