import React from 'react';
import TextField from '@mui/material/TextField';

const StepName = ({ userData, setUserData }) => {
  return (
    <TextField
      label="Display Name"
      value={userData.displayName}
      onChange={(e) => setUserData({ ...userData, displayName: e.target.value })}
      fullWidth
      variant="outlined"
      margin="normal"
    />
  );
};

export default StepName;