import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Typography, Box, Paper, Snackbar, Alert, List, ListItem, ListItemText, Divider } from '@mui/material';
import StepName from '../components/OnboardingStepper/StepName';
import StepPhoneNumber from '../components/OnboardingStepper/StepPhoneNumber';
import StepJobTitle from '../components/OnboardingStepper/StepJobTitle';
import { useNavigate } from 'react-router-dom';
import UsersStore from '../stores/UsersStore';
import authStore from '../stores/AuthStore';

const steps = ['Enter your display name', 'Enter your phone number', 'Enter your job title'];

const Onboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [userData, setUserData] = useState({
    displayName: '',
    phoneNumber: '',
    jobTitle: ''
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const navigate = useNavigate();

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === steps.length - 1) {
        try {
          UsersStore.updateUser(authStore.userId, userData);
          setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
        } catch (error) {
          setSnackbar({ open: true, message: 'Error updating user', severity: 'error' });
        }
      }
      return prevActiveStep + 1
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <StepName userData={userData} setUserData={setUserData} />;
      case 1:
        return <StepPhoneNumber userData={userData} setUserData={setUserData} />;
      case 2:
        return <StepJobTitle userData={userData} setUserData={setUserData} />;
      default:
        return 'Unknown step';
    }
  };

  const handleContinue = () => {
    const isItAdmin = authStore.userRoles.includes('ITADMIN');
    navigate(isItAdmin ? '/users' : '/');
  }

  return (
    <>
      <Paper sx={{ m: 2 }}>
        <Typography variant="h4" sx={{ p: 2 }}>User Onboarding</Typography>
        <Stepper activeStep={activeStep} sx={{ p: 2 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <Box sx={{ m: 2 }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - here's your summary:
              </Typography>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem>
                  <ListItemText primary="Display Name" secondary={<Typography variant="body2">{userData.displayName}</Typography>} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Phone Number" secondary={<Typography variant="body2">{userData.phoneNumber}</Typography>} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                  <ListItemText primary="Job Title" secondary={<Typography variant="body2">{userData.jobTitle}</Typography>} />
                </ListItem>
              </List>
              <Button sx={{ m: 2 }} onClick={handleContinue}>Continue</Button>
            </Box>
          ) : (
            <Box sx={{ m: 2 }}>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ m: 2 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  sx={{ m: 2 }}
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>

  );
};

export default Onboarding;